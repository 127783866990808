<template>
  <app-content-layout>
    <app-sidebar :items="menus" />
    <router-view />
  </app-content-layout>
</template>
<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description index Component Logic
   * @date 25.07.2020
   */
  export default {
    name: "leave",
    data: vm => ({
      items: [
        {
          title: vm.$t("leave.menu.leave_accruals"),
          to: { name: "leave_accruals" },
          icon: "mdi-bag-carry-on",
          active: true,
          visible: true,
          access_key: "leave_accruals"
        },
        {
          title: vm.$t("leave.menu.leave_requests"),
          to: { name: "leave_requests" },
          icon: "tw-leave-free",
          active: true,
          visible: true,
          access_key: "leave_requests"
        },
        {
          title: vm.$t("leave.menu.leave_approvals"),
          to: { name: "leave_approvals" },
          icon: "tw-leave-sending",
          active: true,
          visible: true,
          access_key: "leaveManager"
        }
      ],
      menus: [],
      employee: {}
    }),
    methods: {
      menuGenerate() {
        this.menus = this.items.filter(item => {
          if (item.access_key === "leaveManager") {
            return (item.visible = this.$token_manager.get("leaveManager"));
          } else {
            return (item.visible = true);
          }
        });
      }
    },
    created() {
      this.menuGenerate();
    }
  };
</script>

<style scoped></style>
